var DeviceModelHelper = /** @class */ (function () {
    function DeviceModelHelper() {
    }
    DeviceModelHelper.isChannelsBuiltIn = function (deviceModel, channel) {
        return DeviceModelHelper.deviceModels.some(function (dm) { return dm.channelsBuiltIn.some(function (c) { return c === channel; }) === true && (dm.modelId === deviceModel || dm.name === deviceModel); });
    };
    DeviceModelHelper.getDeviceModelList = function () {
        var modelList = DeviceModelHelper.deviceModels.map(function (models) {
            return { label: models.name, value: models.modelId.toString() };
        });
        return modelList;
    };
    DeviceModelHelper.deviceModels = [
        { modelId: 1, name: 'VT2000', channelsBuiltIn: [0] },
        { modelId: 2, name: 'VT22', channelsBuiltIn: [0] },
        { modelId: 8, name: 'VT3000', channelsBuiltIn: [0] },
        { modelId: 16, name: 'VT3000AI', channelsBuiltIn: [0] },
        { modelId: 4, name: 'VT5500', channelsBuiltIn: [] },
        { modelId: 5, name: 'VT5500G', channelsBuiltIn: [] },
        { modelId: 9, name: 'VT62', channelsBuiltIn: [] },
        { modelId: 6, name: 'VT8000', channelsBuiltIn: [] },
        { modelId: 17, name: 'VT3500', channelsBuiltIn: [0] },
        { modelId: 18, name: 'VT5500C', channelsBuiltIn: [] },
        { modelId: 19, name: 'VT2500', channelsBuiltIn: [0] },
        { modelId: 20, name: 'VT60AI', channelsBuiltIn: [] },
        { modelId: 24, name: 'VT3600', channelsBuiltIn: [0] },
        { modelId: 25, name: 'VT3600AI', channelsBuiltIn: [0] },
        { modelId: 26, name: 'VT63', channelsBuiltIn: [] },
        { modelId: 27, name: 'V5', channelsBuiltIn: [0] },
        { modelId: 35, name: 'VTM1', channelsBuiltIn: [] }
    ];
    return DeviceModelHelper;
}());
export default DeviceModelHelper;
